
import {defineComponent, onMounted, ref} from 'vue';
import router from "@/router";

export default defineComponent({
  name: 'HomeView',
  components: {},
  setup() {
    const currentDate = new Date();
    const isToday = ref(false);
    const isTodayCalc = () => {
      if (currentDate.getMonth() === 0 && currentDate.getDate() === 6) {
        isToday.value = true;
      } else {
        isToday.value = false;
      }
    };
    const addYear = () => {
      if (currentDate.getMonth() === 0) {
        if (currentDate.getDate() >= 1 && currentDate.getDate() <= 5) {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    };
    const countDownDate = new Date(currentDate.getFullYear() + addYear(), 0, 6).getTime();
    const countDownText = ref("");
    const countDownCalc = () => {
      const x = setInterval(function () {

        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the countdown date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        countDownText.value = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

        // If the countdown is over, write some text
        /*if (distance < 0) {
          clearInterval(x);
          countDownText.value = "EXPIRED";
        }*/
      }, 1000);
    };
    const register = () => {
      router.push("/registro");
    };

    onMounted(() => {
      countDownCalc();
      isTodayCalc();
    });

    return {countDownText, isToday, register};
  },
});
