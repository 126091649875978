
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'App',
  components: {
  },
  setup () {
    const screenWidth = ref<number>(0);

    return { screenWidth };
  },
});
